import { useEffect } from 'react';
import Mainroutes from './Routes';

function App() {
	return (
		<Mainroutes />
	);
}

export default App;
