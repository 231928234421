import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from 'vaul';
import { FetchPostApi } from '../Apiservice';
import { API_PATH } from '../const';
import { addData, deleteData, getData } from '../Indexdb/indexedDB';

import myFunction from '../Helper';

import Downloadpdf from '../Componets/Downloadpdf';




export default function Quiz(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [options, setoptions] = useState({
        "page": 1,
        "limit": 50,
    })
    const [paginateOption, setpaginateOption] = useState()
    const [questionlist, setquestionlist] = useState([])
    const [solution_drawer, set_solution_drawer] = useState(false)
    const mathContainerRef = useRef(null);
    const [selectsolutin, set_selectsolutin] = useState({})
    const [select_data, set_select_data] = useState([])


    useEffect(() => {
        if (!location?.state?.title_top) {
            return navigate('/')
        }
        Fetchquestion(options)
        Gettingselectdata()

    }, [location, options])

    async function Fetchquestion(options) {
        const temp = { ...options, whereArray: location.state.data }
        const question = await FetchPostApi(API_PATH.Getquestions, temp)
        if (question.success) {
            setquestionlist(question.data.data)
            setpaginateOption(question.data.paginateOption);
        } else {
            setquestionlist([])
            setpaginateOption();
        }
    }

    const hasnext = () => {
        setoptions({ ...options, page: options.page + 1 })
    }
    const hasprev = () => {
        setoptions({ ...options, page: options.page - 1 })
    }

    function createMarkup(data) {
        return { __html: data };
    }

    useEffect(() => {
        setTimeout(() => {
            // window.MathJax.typesetPromise(); // Trigger MathJax to render LaTeX expressions
            myFunction.reloadMathjax();
        }, 50);
    }, [questionlist, solution_drawer]);

    // useEffect(() => {
    //     if (window.MathJax) {
    //       // Ensure MathJax processes the content
    //       MathJax.Hub.Configured()
    //     }
    //   }, []);

    const selectquestion = async (e, item) => {
        if (e.target.checked) {
            item.title = location.state.title_top
            await addData(item, 'selection')
        } else {
            await deleteData(item.id, 'selection')
        }

        Gettingselectdata()
    }

    
    async function Gettingselectdata(params) {
        const temp = await getData('selection')
        set_select_data(temp ?? [])
    }
    console.log(select_data)


    


    return (
        <div className='container-md' >
            <section className='row py-3 sticky-top z-1 top-0 bg-white shadow-sm'>
                <div className='col-12 d-flex align-items-center'>
                    <div className='back-arrow'>
                        <span className='cursor-pointer' onClick={() => {
                            navigate('/subject' + location.state.current, {
                                state: {
                                    data: location.state.back_state
                                }
                            })
                        }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 8L1 8" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 15L1 8L8 1" stroke="#363D4E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <div className="common-title mx-auto px-2">
                        <h3 className="mb-0" dangerouslySetInnerHTML={createMarkup(location?.state?.title_top)}></h3>
                    </div>

                </div>
            </section>
            <section className='row scroll-quiz'>
                <div className="col-12">
                    <ul className='question-list mt-4'>
                        {questionlist.length > 0 && questionlist.map((item, i) => {
                            const is_select = select_data.length > 0 && select_data.some((e) => e.id == item.id)
                            return (
                                <li>
                                    <div className="question-main-box">
                                        <div className='select-container'>
                                            <label className='me-2 cust-checkbox'>
                                                <input type='checkbox' className='d-none' onChange={(e) => selectquestion(e, item)} checked={is_select} />
                                                <span></span>
                                            </label>
                                        </div>
                                        <div className="question-counter">
                                            {paginateOption.offset + i}
                                        </div>
                                        {item.remark && item.remark.trim() != '' &&
                                            <div className="question-remark line-clamp" onClick={(e) => e.stopPropagation()}>
                                                {item.remark}
                                            </div>
                                        }
                                        <div ref={mathContainerRef}>
                                            <h1 className='cursor-pointer' dangerouslySetInnerHTML={createMarkup(item.question)} onClick={() => {
                                                set_selectsolutin(item)
                                                set_solution_drawer(true)
                                            }}></h1>
                                            <div className="question-mcq">
                                                <ul>
                                                    {item.mcq_1 &&
                                                        <li className="mt-2">
                                                            <div>
                                                                <bdi>A</bdi>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={createMarkup(item.mcq_1)}>
                                                            </div>
                                                        </li>
                                                    }
                                                    {item.mcq_2 &&
                                                        <li className='mt-2'>
                                                            <div>
                                                                <bdi>B</bdi>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={createMarkup(item.mcq_2)}>
                                                            </div>
                                                        </li>
                                                    }
                                                    {item.mcq_3 &&
                                                        <li className='mt-2'>
                                                            <div>
                                                                <bdi>C</bdi>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={createMarkup(item.mcq_3)}>
                                                            </div>
                                                        </li>
                                                    }
                                                    {item.mcq_4 &&
                                                        <li className='mt-2'>
                                                            <div>
                                                                <bdi>D</bdi>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={createMarkup(item.mcq_4)}>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="question-solution-icon" onClick={() => {
                                                set_selectsolutin(item)
                                                set_solution_drawer(true)
                                            }}>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="26" height="26" rx="13" fill="#18AEFF" />
                                                    <path d="M10 16L13 13L10 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13 16L16 13L13 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>


                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
            {select_data.length > 0 &&
                <Downloadpdf data={select_data} removeindexdata={Gettingselectdata} />
            }
            {paginateOption &&
                <section className='row top-0 bg-white sticky-bottom border-top z-1  shadow-sm  pagination'>
                    <div className='col-12 d-flex align-items-center py-3  '>
                        {paginateOption.hasPrev &&

                            <div className='back-arrow' onClick={() => hasprev()}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_209_2937)">
                                        <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                        <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                        <path d="M25.8334 19H14.1667M14.1667 19L20 24.8333M14.1667 19L20 13.1666" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_209_2937" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2937" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2937" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        }
                        <div className="common-title mx-auto">
                            <h3 className="mb-0">Page {options.page} of {paginateOption.lastPage}</h3>
                        </div>
                        {paginateOption.hasNext &&
                            <div className='back-arrow' onClick={() => hasnext()}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_209_2939)">
                                        <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
                                        <rect x="2.5" y="1.5" width="35" height="35" rx="7.5" stroke="#D0D5DD" />
                                        <path d="M14.1666 19H25.8333M25.8333 19L20 13.1666M25.8333 19L20 24.8333" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_209_2939" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_2939" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_2939" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        }
                    </div>
                </section>
            }
            {/* answer drawer */}

            <Drawer.Root
                shouldScaleBackground
                open={solution_drawer}
                // dismissible
                // modal
                onClose={() => set_solution_drawer(false)}
            >
                <Drawer.Portal>
                    <div onClick={() => set_solution_drawer(false)}>
                        <Drawer.Overlay className='cust-overlay-drawer' />
                    </div>
                    <Drawer.Content>
                        <div className='drawer-inner pt-2'>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <div className='border-top-notch'>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row scrollable-drawer solutions-drw'>

                                        <div className='col-md-12'>
                                            <div className='question-main-box'>
                                                <h1>
                                                    <div dangerouslySetInnerHTML={createMarkup(selectsolutin.question)}>
                                                    </div>
                                                </h1>
                                                <div className="question-mcq">
                                                    <ul className='p-0'>
                                                        {selectsolutin.mcq_1 &&
                                                            <li className="mt-2">
                                                                <div>
                                                                    <bdi className={selectsolutin.right_answer == 'A' ? 'active' : ''}>A</bdi>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={createMarkup(selectsolutin.mcq_1)}>
                                                                </div>
                                                            </li>
                                                        }
                                                        {selectsolutin.mcq_2 &&
                                                            <li className='mt-2'>
                                                                <div>
                                                                    <bdi className={selectsolutin.right_answer == 'B' ? 'active' : ''}>B</bdi>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={createMarkup(selectsolutin.mcq_2)}>
                                                                </div>
                                                            </li>
                                                        }
                                                        {selectsolutin.mcq_3 &&
                                                            <li className='mt-2'>
                                                                <div>
                                                                    <bdi className={selectsolutin.right_answer == 'C' ? 'active' : ''}>C</bdi>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={createMarkup(selectsolutin.mcq_3)}>
                                                                </div>
                                                            </li>
                                                        }
                                                        {selectsolutin.mcq_4 &&
                                                            <li className='mt-2'>
                                                                <div>
                                                                    <bdi className={selectsolutin.right_answer == 'D' ? 'active' : ''}>D</bdi>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={createMarkup(selectsolutin.mcq_4)}>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 '>
                                            <h3>solution</h3>
                                            <p dangerouslySetInnerHTML={createMarkup(selectsolutin.solution)}>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>

            
        </div>
    );
}