// src/indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'Vidyadip';
const STORE_NAME = 'VidyadipStore';
const VERSION = 1;

const initDB = async () => {
  const db = await openDB(DB_NAME, VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME);
        // store.createIndex('Selection', ['board_id', 'medium_id', 'standard_id']);
      }
    },
  });
  return db;
};

const clearAllRecords = async () => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  await store.clear();

  await tx.done;
};

const addData = async (data, key) => {
  const { board_id, medium_id, standard_id } = data;

  // Check for existing data with the same composite key
  if (key == 'setting') await clearAllRecords();

  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  if (board_id || medium_id || standard_id) {

    data.board_id = Number(board_id);
    data.medium_id = Number(medium_id);
    data.standard_id = Number(standard_id);
  }


  // Retrieve the existing array from the store
  let storedData = await store.get(key);
  if (!storedData) {
    storedData = [];
  }

  // Append the new data to the array
  storedData.push(data);

  if (key == 'setting') {
    await store.put(storedData[0], key);
  } else {
    await store.put(storedData, key);

  }

  // Store the updated array back in the store


  await tx.done;
};

const getAllData = async (key) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const data = await store.getAll(key);
  await tx.done;
  return data;
};

const getData = async (key) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const data = await store.get(key);
  await tx.done;
  return data;
};

const deleteData = async (id, key) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  // Retrieve the existing array from the store
  let storedData = await store.get(key); // Replace 'yourArrayKey' with the actual key you use to store the array
  if (!storedData) {
    console.log('No data found for the provided key.');
    await tx.done;
    return;
  }

  // Find the index of the item with the given id
  const index = storedData.findIndex(item => item.id === id);
  if (index === -1) {
    console.log(`Item with id ${id} not found.`);
    await tx.done;
    return;
  }

  // Remove the item from the array
  storedData.splice(index, 1);

  // Store the updated array back in the store
  await store.put(storedData, key); // Replace 'yourArrayKey' with the actual key you use to store the array

  await tx.done;
};



export { addData, getData, getAllData, deleteData };
