import React, { useEffect, useState, useRef } from 'react';
import myFunction from '../Helper';
import { getAllData, getData } from '../Indexdb/indexedDB';


function Pdfgenerator({ data, convertpdf, download_option }) {

    const [renderedHtml, setRenderedHtml] = useState([]);
    const contentRef = useRef(null); // Reference to the content div
    const [totalmark, set_totalmark] = useState(0)

    const [pdf_data, set_pdfdata] = useState({
        standard: '',
        subject: '',

    })

    // Function to group the data by topic_id
    const groupByTopicId = (data) => {
        return data.reduce((acc, current) => {
            (acc[current.topic_id] = acc[current.topic_id] || []).push(current);
            return acc;
        }, {});
    };


    useEffect(() => {
        const groupedData = groupByTopicId(data);
        const content = [];

        Object.keys(groupedData).forEach((topicId, idx) => {
            content.push(Writinghtml(groupedData, topicId, idx, download_option));
        });


        setRenderedHtml(content);
        // const generate_pdf_html_create = await Pdfhtml(content)
        convertpdf(contentRef)
        fetchData()
    }, [data, download_option]);


    function createMarkup(data) {
        return { __html: data };
    }

    const fetchData = async () => {
        const allData = await getData('setting');
        const subject = await getData('subject');
        const temp = {
            standard: allData.standard_name,
            subject: subject ? subject[0] : '',
        }

        set_pdfdata(temp)
    }

    useEffect(() => {
        setTimeout(() => {
            // if (window && window.MathJax) {
            //     window.MathJax.typeset(); // Trigger MathJax to render LaTeX expressions
            // }
            myFunction.reloadMathjax();
        }, 50);
    }, [data]);

    let totalMarks = 0;
    // Function to render the HTML content
    const Writinghtml = (groupedData, topicId, idx, download_option) => {

        const title = `Q-${idx + 1} ` + groupedData[topicId][0]?.title;

        const questions = groupedData[topicId]
            .sort((a, b) => a.index_id - b.index_id) // Sort by index_id
            .map((question, index) => {
                totalMarks += question.marks
                set_totalmark(totalMarks)
                const hasMCQs = question.mcq_1 || question.mcq_2 || question.mcq_3 || question.mcq_4;
                return (
                    <>
                        <h1 dangerouslySetInnerHTML={createMarkup(
                            `<span class='pe-1'>(${index + 1})</span>${question.question}${download_option.pyq && question.remark && question.remark.trim() !== '' ? ` (${question.remark})` : ''}`
                        )}></h1>
                        {hasMCQs && (
                            <ul className='mcqs'>
                                {question.mcq_1 && (
                                    <li className="pt-2" dangerouslySetInnerHTML={createMarkup(`<bdi>(A)</bdi>${question.mcq_1}`)}>
                                    </li>
                                )}
                                {question.mcq_2 && (
                                    <li className='pt-2' dangerouslySetInnerHTML={createMarkup(`<bdi>(B)</bdi>${question.mcq_2}`)}>
                                    </li>
                                )}
                                {question.mcq_3 && (
                                    <li className='pt-2' dangerouslySetInnerHTML={createMarkup(`<bdi>(C)</bdi>${question.mcq_3}`)}>
                                    </li>
                                )}
                                {question.mcq_4 && (
                                    <li className='pt-2' dangerouslySetInnerHTML={createMarkup(`<bdi>(D)</bdi>${question.mcq_4}`)}>
                                    </li>
                                )}
                            </ul>
                        )}

                        {question.solution && (
                            <div className='solution-div' dangerouslySetInnerHTML={createMarkup(`<strong>Solution :- </strong> ${question.solution}`)}></div>
                        )}
                    </>
                );
            });

        return (
            <>
                <h1 style={{ fontSize: '18px', fontWeight: '600', padding: '10px 0', color: `var(--black-color)` }} dangerouslySetInnerHTML={createMarkup(title)}>{ }</h1 >
                {/* <ul className='question-list'> */}
                {questions}
                {/* </ul> */}
            </>
        );
    };

    function convertMinutesToHours(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    }

    const is_answer = data.some((e) => e.right_answer)

    return (
        <>
            <div ref={contentRef} className='d-none pdf-content-container'>
                <div className='main-header'>
                    <div className='row'>
                        {download_option.organization_text.trim() != '' &&
                            <div className='col-md-12 text-center'>
                                <h1>{download_option.organization_text}</h1>
                            </div>
                        }
                        <div className='col-md-12 p-0'>
                            <div className='row m-0 align-items-center'>
                                <div className='col-4'>
                                    <table className=''>
                                        <tr>
                                            <td><b>Subject</b></td>
                                            <td>: <b>{pdf_data.subject}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Standard</b></td>
                                            <td>: <b>{pdf_data.standard}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>TotalMark</b></td>
                                            <td>: <b>{totalmark}</b></td>
                                        </tr>
                                    </table>

                                </div>
                                <div className='col-4 text-center'>
                                    <h3 className='exam-name'>{download_option.exam_name}</h3>
                                </div>
                                <div className='col-4'>
                                    <table className=''>
                                        {/* <tr>
                                            <td><b>PaperSet</b></td>
                                            <td>: -</td>
                                        </tr> */}
                                        <tr>
                                            <td><b>Date</b></td>
                                            <td>: <b>{new Date(download_option.exam_date).toLocaleDateString('en-GB').replace(/\//g, '-')}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Time</b></td>
                                            <td>: <b>{convertMinutesToHours(download_option.exam_time)}</b></td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {is_answer && (
                    <div className='answer-key'>
                        <ul>
                            {data.map((item, i) => {
                                const index = i + 1;
                                return <li key={i}>{`${index} - ${item.right_answer}`}</li>; // Use template literals
                            })}
                        </ul>
                    </div>
                )
                }

                {renderedHtml}
            </div>
        </>
    );
}

export default Pdfgenerator;
