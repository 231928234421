var myFunction = {}


myFunction.sendReactNativeMessage = function (ary) {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(ary));
}



myFunction.reloadMathjax = async function () {
    if (window.MathJax && window.MathJax.Hub) {
        // Queue the typesetting
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);

        // Optionally re-run typesetting after a short delay
        setTimeout(() => {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }, 50);
    } else {
        console.error('MathJax is not loaded or accessible.');
    }
};


module.exports = myFunction;